import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
  // Collapse
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  InfoOutlined,
  HelpOutline
  // ExpandLess,
  // ExpandMore
} from '@mui/icons-material';
import BuildIcon from '@mui/icons-material/Build';
// import DescriptionIcon from '@mui/icons-material/Description';
// import WorkIcon from '@mui/icons-material/Work';
// import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import magnaLogo from '../assets/appicon.png';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
// import { useState } from 'react';

const Sidebar = ({ open, handleClose }) => {
  const navigate = useNavigate();
  // const [pspOpen, setPspOpen] = useState(false);

  const handleNavigate = (path) => {
    navigate(path);
    handleClose();
  };

  // const handlePspClick = () => {
  //   setPspOpen(!pspOpen);
  // };

  return (
    <Drawer anchor="left" open={open} onClose={handleClose}>
      <Box
        sx={{
          width: 250,
          backgroundColor: '#800000',
          color: 'white',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto'
        }}
        role="presentation"
        onKeyDown={handleClose}
      >
        <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
          <Box
            component="img"
            src={magnaLogo}
            alt="Magna Logo"
            sx={{ width: '40px', height: '40px', marginRight: '8px' }}
          />
          <Typography variant="h4" fontWeight="bold">
            MAGNA
          </Typography>
        </Box>
        <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
          <BuildIcon style={{ marginRight: '30px' }} />
          <Typography variant="h6" fontWeight="bold">
            QuoVaDis
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <List>
            {/* <ListItem button onClick={handlePspClick}>
              <ListItemIcon>
                <AccountTreeIcon style={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText primary="PSP View" />
              {pspOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={pspOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button sx={{ pl: 4 }} onClick={() => handleNavigate('/psp/pre-equote')}>
                  <ListItemIcon>
                    <DescriptionIcon style={{ color: 'white' }} />
                  </ListItemIcon>
                  <ListItemText primary="Pre eQuote" />
                </ListItem>
                <ListItem button sx={{ pl: 4 }} onClick={() => handleNavigate('/psp/equote')}>
                  <ListItemIcon>
                    <DescriptionIcon style={{ color: 'white' }} />
                  </ListItemIcon>
                  <ListItemText primary="eQuote" />
                </ListItem>
              </List>
            </Collapse>
            <ListItem button onClick={() => handleNavigate('/work-packages')}>
              <ListItemIcon>
                <WorkIcon style={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText primary="Work Packages" />
            </ListItem>
            <ListItem button onClick={() => handleNavigate('/work-plans')}>
              <ListItemIcon>
                <WorkIcon style={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText primary="Work Plans" />
            </ListItem> */}
            <ListItem button onClick={() => handleNavigate('/equote-number-approval')}>
              <ListItemIcon>
                <CheckBoxIcon style={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText primary="Approvals" />
            </ListItem>
          </List>
        </Box>
        <Box>
          <List>
            <ListItem button onClick={() => handleNavigate('/about')}>
              <ListItemIcon>
                <InfoOutlined style={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText primary="About" />
            </ListItem>
            <ListItem button onClick={() => handleNavigate('/contact-support')}>
              <ListItemIcon>
                <SupportAgentIcon style={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText primary="Support" />
            </ListItem>
            <ListItem button onClick={() => handleNavigate('/faq')}>
              <ListItemIcon>
                <HelpOutline style={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText primary="FAQ" />
            </ListItem>
          </List>
        </Box>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
