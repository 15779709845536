import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  IconButton,
  Paper,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import ProgressSpinner from '../../Components/ProgressSpinner.jsx';
import BackToDashboard from '../../Components/BackToDashboard.jsx';
import AlertMessage from '../../Components/AlertMessage.jsx';
import { useNavigate } from 'react-router-dom';
import msalInstance from '../../msalInstance.js';
import { loginRequest } from '../../authConfig.js';
import axios from 'axios';
import { ArrowBack } from '@mui/icons-material';

const AddNewCustomer = () => {
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [customers, setCustomers] = useState([]);
  const [newCustomer, setNewCustomer] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const getToken = async () => {
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
      const accessToken = tokenResponse.idToken;
      return accessToken;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCustomers = async () => {
    try {
      setLoading(true);
      const accessToken = await getToken();
      const response = await axios.get(`${apiUrl}/api/customer`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      setCustomers(response.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
      setAlertSeverity('error');
      setAlertMessage('Failed to load customer data.');
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (newCustomer.trim() === '') {
      setAlertMessage('Customer name cannot be empty.');
      setAlertSeverity('error');
      setAlertOpen(true);
      return;
    }

    try {
      setLoading(true);
      const accessToken = await getToken();

      await axios.post(
        `${apiUrl}/api/customer`,
        { customerName: newCustomer },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );

      setOpenDialog(true);
      setNewCustomer('');
      fetchCustomers();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setAlertMessage('Customer already exists!');
      } else {
        console.error('Error adding customer:', error);
        setAlertMessage('An error occurred while adding the customer.');
      }

      setAlertSeverity('error');
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const filteredCustomers = customers.filter((customer) =>
    customer.customerName.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <>
      {loading && <ProgressSpinner />}
      <AlertMessage
        open={alertOpen}
        message={alertMessage}
        severity={alertSeverity}
        onClose={() => setAlertOpen(false)}
      />

      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#f5f5f5'
        }}
      >
        <Box>
          <BackToDashboard />
        </Box>

        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '16px'
          }}
        >
          <Paper
            sx={{
              padding: '16px',
              width: '90vw',
              maxHeight: '80vh',
              overflow: 'auto',
              textAlign: 'center',
              '&::-webkit-scrollbar': {
                width: '0.4em'
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#c2c1c1',
                borderRadius: '10px'
              }
            }}
          >
            <Box display="flex" mb="1rem">
              <IconButton onClick={() => navigate('/create-project')} style={{ color: '#800000' }}>
                <ArrowBack />
                <Typography pl="0.5rem">Back</Typography>
              </IconButton>
            </Box>

            <Box
              component="form"
              autoComplete="off"
              onSubmit={handleSubmit}
              display="flex"
              flexDirection="column"
              alignItems="center"
              mt="2rem"
            >
              <TextField
                label="New Customer Name"
                variant="outlined"
                value={newCustomer}
                onChange={(e) => setNewCustomer(e.target.value)}
                required
                sx={{ width: '30vw', marginBottom: '1rem' }}
              />

              <Button
                type="submit"
                variant="contained"
                sx={{
                  p: '0.5rem',
                  mt: '0.5rem'
                }}
              >
                Add Customer
              </Button>
            </Box>

            <Box
              mt={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Available Customers
              </Typography>

              <Typography variant="body2" color="textSecondary" sx={{ marginBottom: '1rem' }}>
                <Box component="span" fontSize="0.85rem">
                  Kindly check the available list below before adding a new customer
                </Box>
              </Typography>

              <TextField
                label="Search Customers"
                variant="outlined"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                sx={{ marginBottom: '1rem', width: '30vw' }}
              />

              <List
                sx={{
                  maxHeight: '40vh',
                  overflow: 'auto',
                  width: '30vw',
                  transition: 'all 0.3s ease-in-out',
                  backgroundColor: '#f5f5f5',
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                  padding: '1rem',
                  borderRadius: '8px'
                }}
              >
                {filteredCustomers.length > 0 ? (
                  filteredCustomers.map((customer) => (
                    <ListItem
                      key={customer.id}
                      sx={{
                        transition: 'transform 0.3s ease',
                        '&:hover': {
                          transform: 'scale(1.05)',
                          backgroundColor: '#e0f7fa'
                        }
                      }}
                    >
                      <ListItemText primary={customer.customerName} />
                    </ListItem>
                  ))
                ) : (
                  <Typography>No matching customers found</Typography>
                )}
              </List>
            </Box>

            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle id="success-dialog-title">
                New Customer has been successfully added!
              </DialogTitle>
              <DialogActions sx={{ justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  onClick={handleCloseDialog}
                  sx={{
                    width: '10vw'
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default AddNewCustomer;
