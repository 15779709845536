import { AppBar, Toolbar, Typography } from '@mui/material';
import Logo from '../assets/quovadis.png';
import { useNavigate, useLocation } from 'react-router-dom';
import React from 'react';

const BackToDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const routeNames = {
    '/configuration': 'Project Configuration',
    '/assumptions': 'Project Assumption',
    '/timing': 'Project Timing',
    '/work-plans': 'Work Plans',
    '/work-packages': 'Work Package',
    '/totals': 'Totals',
    '/review': 'Review',
    '/equote-generate': 'eQuote Generate',
    '/create-project': 'Create Project',
    '/psp/pre-equote': 'Pre-eQuote | Project Structure Plan',
    '/psp/equote': 'eQuote | Project Structure Plan',
    '/add-new-customer': 'Add Customer',
    '/profile': 'Profile',
    '/equote-number-approval': 'eQuote Number Approval',
    '/equote-number-approval/tab1': 'eQuote Number Approval',
    '/equote-number-approval/tab2': 'eQuote Number Approval',
    '/contact-support': 'Contact Support'
  };

  const routeName = routeNames[location.pathname];

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <div>
      <AppBar position="static" sx={{ bgcolor: 'white', color: 'Maroon' }}>
        <Toolbar>
          <button
            onClick={handleLogoClick}
            style={{
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              padding: '0 1rem'
            }}
          >
            <img src={Logo} alt="logo" />
          </button>
          <Typography variant="h4" style={{ fontWeight: 'bold' }}>
            {routeName}
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default BackToDashboard;
