import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import Assumptions from '../Pages/Assumptions/Assumptions';
import Configurations from '../Pages/ConfigurationsPage/Configurations';
import PSP_Quote from '../Pages/PSP_Quote/PSP_Quote';
import Review from '../Pages/ReviewPage/Review';
import Timing from '../Pages/Timing/Timing';
import Totals from '../Pages/Totals/Totals';
import WorkPackage from '../Pages/WorkPackage/WorkPackage';
import WorkPlans from '../Pages/WorkPlans/WorkPlans';
import LoginPage from '../Pages/Login/LoginPage';
import QGuard from '../Pages/QGuard/QGuard';
import Quasi from '../Pages/Quasi/Quasi';
import ProfilePage from '../Pages/Profile/ProfilePage';
import Cockpit from '../Pages/Cockpit/Cockpit';
import GenerateQuote from '../Pages/GenerateQuote/GenerateQuote';
import CreateNewProject from '../Pages/CreateNewProject/CreateNewProject';
import AboutPage from '../Pages/About/AboutPage';
import FaqPage from '../Pages/FAQ/FaqPage';
import NotFoundPage from '../Pages/NotFoundPage/NotFoundPage';
import PreQuotePSP from '../Pages/PreQuotePSP/PreQuotePSP';
import AddNewCustomer from '../Pages/AddNewCustomer/AddNewCustomer';
import UnauthorizedPage from '../Pages/Unauthorized/UnauthorizedPage';
import RoleBasedRoute from './RoleBasedRoute';
import EQuoteApprovalPage from '../Pages/eQuoteNumberApproval/eQuoteNumberApproval';
import EQuoteVersionUpdate from '../Pages/eQuoteVersionUpdate/eQuoteVersionUpdate';
import TbdPages from '../Pages/TbdPages/TbdPages';
import Support from '../Pages/Support/Support';

const PageLayout = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <Routes>
          <Route path="/" element={<Cockpit />} />
          <Route
            path="/equote-generate"
            element={
              <RoleBasedRoute requiredRoles={['eQuote.Manager', 'QVD.Admin', 'eQuote.ProjectLead']}>
                <GenerateQuote />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/create-project"
            element={
              <RoleBasedRoute requiredRoles={['QVD.Admin', 'eQuote.Manager']}>
                <CreateNewProject />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/assumptions"
            element={
              <RoleBasedRoute requiredRoles={['eQuote.Manager', 'eQuote.ProjectLead']}>
                <Assumptions />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/configuration"
            element={
              <RoleBasedRoute requiredRoles={['eQuote.Manager', 'eQuote.ProjectLead']}>
                <Configurations />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/psp/equote"
            element={
              <RoleBasedRoute requiredRoles={['eQuote.Reviewer']}>
                <PSP_Quote />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/psp/pre-equote"
            element={
              <RoleBasedRoute requiredRoles={['eQuote.Reviewer']}>
                <PreQuotePSP />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/review"
            element={
              <RoleBasedRoute requiredRoles={['eQuote.Approver']}>
                <Review />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/timing"
            element={
              <RoleBasedRoute requiredRoles={['eQuote.ProjectLead']}>
                <Timing />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/totals"
            element={
              <RoleBasedRoute requiredRoles={['eQuote.Manager']}>
                <Totals />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/equote-version-update"
            element={
              <RoleBasedRoute requiredRoles={['eQuote.Manager', 'eQuote.ProjectLead', 'QVD.Admin']}>
                <EQuoteVersionUpdate />
              </RoleBasedRoute>
            }
          />

          <Route
            path="/work-packages"
            element={
              <RoleBasedRoute requiredRoles={['eQuote.Manager', 'eQuote.ProjectLead', 'QVD.Admin']}>
                <WorkPackage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/work-plans"
            element={
              <RoleBasedRoute requiredRoles={['QVD.Admin']}>
                <WorkPlans />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/add-new-customer"
            element={
              <RoleBasedRoute requiredRoles={['QVD.Admin']}>
                <AddNewCustomer />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/qguard"
            element={
              <RoleBasedRoute requiredRoles={['eQuote.Reviewer']}>
                <QGuard />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/quasi"
            element={
              <RoleBasedRoute requiredRoles={['eQuote.Manager']}>
                <Quasi />
              </RoleBasedRoute>
            }
          />
          <Route path="/equote-number-approval" element={<EQuoteApprovalPage />} />
          <Route path="/equote-number-approval/:tabId" element={<EQuoteApprovalPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/contact-support" element={<Support />} />
          <Route path="/unauthorized" element={<UnauthorizedPage />} />
          <Route path="/work-in-progress" element={<TbdPages />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Routes>
          <Route path="*" element={<LoginPage />} />
        </Routes>
      </UnauthenticatedTemplate>
    </>
  );
};

export default PageLayout;
