import { Box } from '@mui/material';
import React from 'react';
import QuasiImg from '../../assets/quasi.jpg';

const Quasi = () => {
  return (
    <Box sx={{ backgroundColor: '#DCDCDC', display: 'flex', height: '100vh' }}>
      <img src={QuasiImg} alt="Quasi" style={{ margin: 'auto' }} />
    </Box>
  );
};

export default Quasi;
