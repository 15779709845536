import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  TextField,
  useMediaQuery,
  useTheme,
  Autocomplete
} from '@mui/material';
import msalInstance from '../../msalInstance';
import { loginRequest } from '../../authConfig';
import BackToDashboard from '../../Components/BackToDashboard';
import useHasRole from '../../hooks/useHasRole';
import AlertMessage from '../../Components/AlertMessage';
import ProgressSpinner from '../../Components/ProgressSpinner';

const EQuoteVersionUpdate = () => {
  const [eQuoteData, setEQuoteData] = useState([]);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [eQuoteType, setEQuoteType] = useState('');
  const [eQuoteCharacter, setEQuoteCharacter] = useState('');
  const [quoteFinishTargetDate, setQuoteFinishTargetDate] = useState('');
  const [eQuoteCustomerRequestDate, setEQuoteCustomerRequestDate] = useState('');
  const [eQuoteDescription, setEQuoteDescription] = useState('');
  const [eQuoteDueDate, setEQuoteDueDate] = useState('');
  const [eQuoteKickoffDate, setEQuoteKickoffDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const isAllowedRoles = useHasRole(['QVD.Admin', 'eQuote.Manager']);
  const [userOptions, setUserOptions] = useState([]);
  const [eQuoteLead, setEQuoteLead] = useState('');
  const [eQuoteSecondLead, setEQuoteSecondLead] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [alertOpen, setAlertOpen] = useState(false);
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getToken = async () => {
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
      return tokenResponse.idToken;
    } catch (error) {
      console.log(error);
    }
  };

  const getAccessToken = async () => {
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
      const accessToken = tokenResponse.accessToken;
      return accessToken;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const accessToken = await getToken();
        const response = await axios.get(`${apiUrl}/api/user/all`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        const users = response.data
          .filter((user) => user.qvdRole === 'eQuote.ProjectLead')
          .map((user) => ({
            userId: user.userID,
            userName: user.username
          }));
        setUserOptions(users);
      } catch (err) {
        setError('Failed to fetch users');
        console.error(err);
      }
    };

    fetchUsers();
  }, []);

  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchEQuotes = async () => {
    try {
      setLoading(true);
      const accessToken = await getToken();
      const response = await axios.get(
        `${apiUrl}/api/equotes/approvalStatus?eQuoteNumberApproval=Approved`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );
      setEQuoteData(response.data);
    } catch (err) {
      console.error(err);
      const errorMessage =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : 'An error occurred during the API call.';
      setAlertSeverity('error');
      setAlertMessage(errorMessage);
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEQuotes();
  }, []);

  const handleQuoteChange = (event, newValue) => {
    if (newValue) {
      setSelectedQuote(newValue);
      setEQuoteType(newValue.eQuoteType);
      setEQuoteCharacter(newValue.eQuoteCharacter);
      setQuoteFinishTargetDate(newValue.quoteFinishTargetDate || null);
      setEQuoteCustomerRequestDate(newValue.eQuoteCustomerRequestDate || null);
      setEQuoteDueDate(newValue.eQuoteDueDate || null);
      setEQuoteKickoffDate(newValue.eQuoteKickoffDate || null);
      setEQuoteLead(newValue.eQuoteLead || null);
      setEQuoteSecondLead(newValue.secondLead || null);
    } else {
      setSelectedQuote(null);
    }
  };

  const handleUpdate = async () => {
    if (!eQuoteLead || !eQuoteCharacter || !eQuoteCustomerRequestDate || !eQuoteDescription) {
      setError('Please fill in all required fields.');
      return;
    }

    if (!selectedQuote) return;

    try {
      setLoading(true);
      const accessToken = await getToken();

      const response = await axios.patch(
        `${apiUrl}/api/equotes/${selectedQuote.quoteID}/version`,
        {
          eQuoteType,
          eQuoteCharacter,
          quoteFinishTargetDate: quoteFinishTargetDate || null,
          eQuoteCustomerRequestDate,
          eQuoteDescription: eQuoteDescription || null,
          eQuoteDueDate: eQuoteDueDate || null,
          eQuoteKickoffDate: eQuoteKickoffDate || null,
          eQuoteLead,
          secondLead: eQuoteSecondLead === '' ? null : eQuoteSecondLead
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );

      const updatedEQuoteNumber = response.data.eQuoteNumber;

      setDialogMessage(`eQuote Number updated to ${updatedEQuoteNumber} successfully!`);
      setDialogOpen(true);

      if (!isAllowedRoles) {
        await sendMail(updatedEQuoteNumber);
      }
    } catch (err) {
      console.error(err);
      const errorMessage =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : 'An error occurred during the API call.';
      setAlertSeverity('error');
      setAlertMessage(errorMessage);
      setAlertOpen(true);
    } finally {
      setLoading(false);
      setError(false);
    }
  };

  const sendMail = async (updatedEQuoteNumber) => {
    const accessToken = await getAccessToken();

    const regionEmails = {
      EU: process.env.REACT_APP_ADMIN_MAIL_EU || 'ijaz.m@partner.magna.com',
      CN: process.env.REACT_APP_ADMIN_MAIL_CN || 'ijaz.m@partner.magna.com',
      GL: process.env.REACT_APP_ADMIN_MAIL_GL || 'ijaz.m@partner.magna.com',
      NA: process.env.REACT_APP_ADMIN_MAIL_NA || 'ijaz.m@partner.magna.com'
    };

    const recipientEmail = (regionEmails[selectedQuote.region] || 'ijaz.m@partner.magna.com')
      .split(',')
      .map((email) => ({ emailAddress: { address: email.trim() } }));
    const defaultBccEmail = process.env.REACT_APP_ADMIN_EMAIL_DEF || 'ijaz.m@partner.magna.com';
    const webUrl = `${window.location.origin}/equote-number-approval`;

    try {
      setLoading(true);
      const response = await fetch('https://graph.microsoft.com/v1.0/me/sendMail', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          message: {
            subject: `[QuoVaDis] eQuote Number Approval Request ${updatedEQuoteNumber}`,
            body: {
              contentType: 'HTML',
              content: `
                <p>Hello,</p>
                <p>New request for eQuote Number approval:</p>
                <ul>
                  <li><strong>eQuote Number:<strong> ${updatedEQuoteNumber}</li> <!-- And here -->
                  <li><strong>Region:<strong> ${selectedQuote.region}</li>
                  <li><strong>eQuote Short Name:<strong> ${selectedQuote.eQuoteShortName}</li>
                </ul>
                <p>Please click the button below to approve, edit, or reject the request:</p>
                <a href="${webUrl}" style="
                  display: inline-block;
                  padding: 10px 20px;
                  margin-top: 10px;
                  color: #fff;
                  background-color: #800000;
                  text-decoration: none;
                  border-radius: 5px;
                  font-weight: bold;
                  ">VIEW REQUEST</a> 
              `
            },
            toRecipients: recipientEmail,
            bccRecipients: [{ emailAddress: { address: defaultBccEmail } }]
          }
        })
      });

      if (response.ok) {
        setAlertSeverity('success');
        setAlertMessage('Notified Admin through mail successfully!');
        setAlertOpen(true);
      } else {
        console.error('Failed to send mail', response);
        setAlertSeverity('warning');
        setAlertMessage('Update successful, but failed to notify admin via email.');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error('Error sending mail:', error);
      setAlertSeverity('error');
      setAlertMessage('Error notifying Admin, please contact Admin manually.');
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setSelectedQuote(null);
    setEQuoteType('');
    setEQuoteCharacter('');
    setQuoteFinishTargetDate('');
    setEQuoteCustomerRequestDate('');
    setEQuoteDescription('');
    setEQuoteDueDate('');
    setEQuoteKickoffDate('');
    setEQuoteLead('');
    setEQuoteSecondLead('');
    setError(false);
    setAlertOpen(false);
  };

  const handleDialogClose = async () => {
    navigate('/');
  };

  const eQuoteTypeOptions = [
    { label: 'pre eQuote (ACQ Phase)', value: 'pre-eQuote' },
    { label: 'eQuote light (< €/$ 1 Mils)', value: 'eQuote light' },
    { label: 'eQuote (> €/$ 1 Mils)', value: 'eQuote' },
    { label: 'eQuote estimate', value: 'eQuote estimate' }
  ];

  const filteredOptions = useMemo(
    () =>
      eQuoteTypeOptions.filter((option) => {
        if (selectedQuote) {
          return (
            !(
              selectedQuote.eQuoteType === 'eQuote light' ||
              selectedQuote.eQuoteType === 'eQuote estimate' ||
              selectedQuote.eQuoteType === 'eQuote'
            ) || option.value !== 'pre-eQuote'
          );
        }
        return true;
      }),
    [selectedQuote]
  );

  return (
    <>
      {loading && <ProgressSpinner />}
      <AlertMessage
        open={alertOpen}
        message={alertMessage}
        severity={alertSeverity}
        onClose={() => setAlertOpen(false)}
      />
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#ebebeb'
        }}
      >
        <BackToDashboard />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            padding: theme.spacing(2)
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: '600px',
              backgroundColor: '#fff',
              padding: theme.spacing(3),
              borderRadius: '8px',
              boxShadow: 3
            }}
          >
            <Typography variant="h5" gutterBottom>
              eQuote Version Update
            </Typography>
            <FormControl fullWidth margin="normal">
              <Autocomplete
                id="quote-autocomplete"
                options={eQuoteData}
                getOptionLabel={(option) => option.eQuoteNumber}
                value={selectedQuote}
                onChange={(event, newValue) => handleQuoteChange(event, newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select eQuote Number"
                    variant="outlined"
                    margin="normal"
                    placeholder="Search eQuote Number"
                  />
                )}
              />
            </FormControl>

            {selectedQuote && (
              <Box
                sx={{
                  marginTop: theme.spacing(2),
                  border: '1px solid #ccc',
                  padding: theme.spacing(2),
                  borderRadius: 1
                }}
              >
                <Typography variant="subtitle1" gutterBottom>
                  <strong>eQuote Number:</strong> {selectedQuote.eQuoteNumber}
                </Typography>

                <FormControl
                  fullWidth
                  sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
                  required
                >
                  <InputLabel id="eQuoteLead-select-label">eQuote Lead</InputLabel>
                  <Select
                    labelId="eQuoteLead-select-label"
                    label="eQuote Lead"
                    value={eQuoteLead}
                    onChange={(e) => setEQuoteLead(e.target.value)}
                    variant="outlined"
                  >
                    {userOptions.map((user) => (
                      <MenuItem key={user.userId} value={user.userId}>
                        {user.userName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl
                  fullWidth
                  sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
                >
                  <InputLabel id="eQuoteSecondLead-select-label">eQuote 2nd Lead</InputLabel>
                  <Select
                    labelId="eQuoteSecondLead-select-label"
                    label="eQuote 2nd Lead"
                    value={eQuoteSecondLead}
                    onChange={(e) => setEQuoteSecondLead(e.target.value)}
                    variant="outlined"
                  >
                    <MenuItem value="">--None--</MenuItem>
                    {userOptions.map((user) => (
                      <MenuItem key={user.userId} value={user.userId}>
                        {user.userName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
                >
                  <InputLabel id="eQuoteType-select-label">eQuote Type</InputLabel>
                  <Select
                    labelId="eQuoteType-select-label"
                    label="eQuote Type"
                    value={eQuoteType}
                    onChange={(e) => setEQuoteType(e.target.value)}
                    variant="outlined"
                  >
                    {filteredOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth sx={{ marginBottom: theme.spacing(2) }} required>
                  <InputLabel id="eQuoteCharacter-select-label">eQuote Character</InputLabel>
                  <Select
                    labelId="eQuoteCharacter-select-label"
                    label="eQuote Character"
                    value={eQuoteCharacter}
                    onChange={(e) => setEQuoteCharacter(e.target.value)}
                    variant="outlined"
                  >
                    <MenuItem value="binding">Binding</MenuItem>
                    <MenuItem value="non-binding">Non-binding</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  fullWidth
                  label="eQuote Description"
                  value={eQuoteDescription}
                  onChange={(e) => setEQuoteDescription(e.target.value)}
                  variant="outlined"
                  required
                  sx={{ marginBottom: theme.spacing(2) }}
                />

                <TextField
                  fullWidth
                  label="Customer Request Date"
                  value={eQuoteCustomerRequestDate.split('T')[0]}
                  onChange={(e) => setEQuoteCustomerRequestDate(e.target.value)}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  required
                  sx={{ marginBottom: theme.spacing(2) }}
                />

                <TextField
                  fullWidth
                  label="eQuote Finished Target Date"
                  value={quoteFinishTargetDate ? quoteFinishTargetDate.split('T')[0] : ''}
                  onChange={(e) => setQuoteFinishTargetDate(e.target.value)}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  sx={{ marginBottom: theme.spacing(2) }}
                />

                <TextField
                  fullWidth
                  label="Due Date"
                  value={eQuoteDueDate ? eQuoteDueDate.split('T')[0] : ''}
                  onChange={(e) => setEQuoteDueDate(e.target.value)}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  sx={{ marginBottom: theme.spacing(2) }}
                />

                <TextField
                  fullWidth
                  label="Kickoff Date"
                  value={eQuoteKickoffDate ? eQuoteKickoffDate.split('T')[0] : ''}
                  onChange={(e) => setEQuoteKickoffDate(e.target.value)}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  sx={{ marginBottom: theme.spacing(2) }}
                />
              </Box>
            )}

            {error && (
              <Typography color="error" variant="body2" gutterBottom>
                {error}
              </Typography>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
              {selectedQuote && (
                <>
                  <Button
                    variant="contained"
                    onClick={handleUpdate}
                    sx={{
                      marginRight: 2
                    }}
                  >
                    Update
                  </Button>

                  <Button variant="outlined" onClick={handleCancel}>
                    Cancel
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Box>

        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>eQuote Update</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {isAllowedRoles ? dialogMessage : 'eQuote Version Update Requested for Approval'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} variant="contained" color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default EQuoteVersionUpdate;
